import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { LegalEntityDetailsResponseT } from 'react-packages/apiSpecification/legalEntityDetails';
import { merchantProfileApiFetch } from 'react-packages/utils/apiFetch';

export const queryKeyword = 'LEGAL_ENTITY_DETAILS';

// ========= QUERY =========
export function useLegalEntityDetailsQuery(
  legalEntityId?: string
): UseQueryResult<LegalEntityDetailsResponseT> {
  const fetchData = () =>
    merchantProfileApiFetch<LegalEntityDetailsResponseT>({
      url: `/legal-entities/${legalEntityId}/details`,
    });

  return useQuery({
    queryKey: [queryKeyword, legalEntityId],
    queryFn: fetchData,
    enabled: Boolean(legalEntityId),
  });
}
