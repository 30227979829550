import { useContext, useState } from 'react';
import { RouterDomContext } from 'react-packages/context/RouterDomContext';

export interface HistoryStateT {
  push: (path: string) => void;
  replace: (path: string) => void;
  goBack: () => string;
  location: Location;
}

export const useCreateHistory = (): HistoryStateT => {
  const [history, setHistory] = useState([window.location.pathname]);

  const push = (path: string) => {
    window.history.pushState({}, '', path);
    setHistory((prevHistory) => [...prevHistory, path]);
  };

  const replace = (path: string) => {
    window.history.replaceState({}, '', path);
    setHistory((prevHistory) => {
      const newHistory = [...prevHistory];
      newHistory[newHistory.length - 1] = path;
      return newHistory;
    });
  };

  const goBack = () => {
    if (history.length > 1) {
      const newPath = history[history.length - 2];
      window.history.back();
      setHistory((prevHistory) => prevHistory.slice(0, -1));
      return newPath;
    }
    return '/';
  };

  return {
    push,
    replace,
    goBack,
    location: window.location,
  };
};

export const useHistory = (): HistoryStateT => {
  return useContext(RouterDomContext).history;
};
