import { decamelizeKeys } from 'react-packages/utils/decamelizeData';
import { camelizeKeys } from 'react-packages/utils/camelizeData';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

export const decamelizeRequestHandler = (
  config: AxiosRequestConfig
): unknown => {
  const newConfig = { ...config };

  if (newConfig.data) {
    newConfig.data = decamelizeKeys(config.data);
  }

  if (newConfig.params) {
    newConfig.params = decamelizeKeys(config.params);
  }
  return newConfig;
};

export const camelizeResponseHandler = (
  response: AxiosResponse<unknown>
): unknown => {
  const newResponse = { ...response };

  if (newResponse.data) {
    newResponse.data = camelizeKeys(response.data);
  }

  return newResponse;
};
