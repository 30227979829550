import { ReactElement } from 'react';
import { Box } from '@partner-tech/pax-ui-kit';
import TaxIdentificationContainer from './TaxIdentification';
import { canEnableDac7Changes } from 'react-packages/utils/featureFlags';

const CompanyComplianceDetails = (): ReactElement | null => {
  if (!canEnableDac7Changes()) return null;
  return (
    <Box bgcolor="#fafafa">
      <TaxIdentificationContainer />
    </Box>
  );
};

export default CompanyComplianceDetails;
