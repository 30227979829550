import useParams, { AppParamsT } from 'react-packages/hooks/useParams';
import { useMerchantQuery } from 'react-packages/queries/useMerchantQuery';
import { useLegalEntityDetailsQuery } from 'react-packages/queries/useLegalEntityDetailsQuery';
import { useLegalEntityQuery } from 'react-packages/queries/useLegalEntityQuery';
import { useTinsQuery } from 'react-packages/queries/useTinsQuery';
import Loading from 'react-packages/components/SectionLoader';
import Presentation from './Presentation';
import { ReactElement } from 'react';

const TaxIdentificationContainer = (): ReactElement => {
  const { merchantId } = useParams<AppParamsT>();
  const { data: merchant } = useMerchantQuery(merchantId);
  let legalEntityId = merchant?.legalEntityId;
  const { data: legalEntityDetails } =
    useLegalEntityDetailsQuery(legalEntityId);
  const { data: legalEntity } = useLegalEntityQuery(legalEntityId);
  const { data: tins } = useTinsQuery(legalEntityId);

  if (legalEntity && tins && legalEntityDetails) {
    return (
      <Presentation
        legalEntityDetails={legalEntityDetails}
        isSoleProprietor={legalEntity.isSoleProprietor}
        tins={tins}
      />
    );
  }

  return <Loading />;
};

export default TaxIdentificationContainer;
