import { useContext } from 'react';
import { RouterDomContext } from 'react-packages/context/RouterDomContext';

export interface AppParamsT {
  merchantId: string;
}

const useParams = <T = Record<string, string>>(): T => {
  return useContext(RouterDomContext).params as T;
};

export default useParams;
