import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    VCard,
    {
      staticClass: "pa-6 rounded",
      class: { "invalid-selection": _vm.invalidSelection },
      attrs: { outlined: "", tile: "", rounded: "sm" },
    },
    [
      _c(
        VFlex,
        { staticClass: "d-flex justify-space-between align-center" },
        [
          _c(
            "div",
            [
              _c(
                VChip,
                {
                  attrs: {
                    color: "#d6deec",
                    "text-color": "#283b8a",
                    small: "",
                  },
                },
                [
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v(_vm._s(_vm.carrierInfo.deliveryDestinationType)),
                  ]),
                ]
              ),
              _vm.businessModelRule
                ? _c(
                    VChip,
                    {
                      staticClass: "ml-2",
                      attrs: {
                        color: "#d6deec",
                        "text-color": "#283b8a",
                        small: "",
                      },
                    },
                    [
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v(_vm._s(_vm.businessModelRule)),
                      ]),
                    ]
                  )
                : _vm._e(),
              _c("div", { staticClass: "my-4" }, [
                _c(
                  "span",
                  {
                    staticClass:
                      "text-subtitle-2 font-weight-bold mr-2 black--text",
                  },
                  [_vm._v("Opt-in services:")]
                ),
                _c("span", { staticClass: "text-body-2" }, [
                  _vm._v(
                    _vm._s(
                      _vm.carrierInfo.configuredOptionalDeliveryCapabilities.join(
                        ", "
                      ) || "Not Specified"
                    )
                  ),
                ]),
              ]),
              _c("div", [
                _c(
                  "span",
                  {
                    staticClass:
                      "text-subtitle-2 font-weight-bold mr-2 black--text",
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.carrierInfo.capabilities.length > 1
                          ? "Parcel Services"
                          : "Parcel Service"
                      ) + ":"
                    ),
                  ]
                ),
                _c("span", { staticClass: "text-body-2" }, [
                  _vm._v(
                    _vm._s(
                      _vm.carrierInfo.capabilities.join(", ") || "Not Specified"
                    )
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                VBtn,
                {
                  staticClass: "mt-4 text-none rounded font-weight-bold",
                  attrs: {
                    loading: _vm.isLoading,
                    outlined: "",
                    color: "black",
                    disabled: _vm.disableDelete,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleDelete(_vm.carrierInfo.id)
                    },
                  },
                },
                [_vm._v("Delete")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm.invalidSelection
        ? _c(
            "div",
            { staticClass: "mt-4 alert-text" },
            [
              _c(VIcon, { staticClass: "mr-1 justify-center" }, [
                _vm._v("mdi-information-outline"),
              ]),
              _c("span", { staticClass: "mt-1" }, [
                _vm._v(
                  "Invalid delivery method. Please delete it and set up a new one."
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }