import axios from 'axios';
import { camelizeResponseHandler, decamelizeRequestHandler } from './helper';
import { anyPass, is, pipe } from 'ramda';
import {
  ApiFetchConfigT,
  ApiMethodT,
  ApiResponseT,
  ApiServicesT,
  ServiceUrlT,
} from './types';
import handleApiError from 'react-packages/utils/apiFetch/errorHandler';

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const isObjectOrArray = anyPass([is(Object), is(Array)]);

const getConfig = (config: ApiFetchConfigT) => {
  const shouldUseDefaultHeaders =
    isObjectOrArray(config.body) || config.method === ApiMethodT.GET;
  return {
    baseURL: config.serviceUrl,
    method: config.method || ApiMethodT.GET,
    headers: {
      ...(shouldUseDefaultHeaders && defaultHeaders),
      ...config.headers,
    },
    responseType: config.responseType || 'json',
  };
};

const apiFetch = <ResponseT>(
  config: ApiFetchConfigT
): ApiResponseT<ResponseT> => {
  const apiConfig = getConfig(config);

  const api = axios.create(apiConfig);

  api.interceptors.request.use(decamelizeRequestHandler);

  if (apiConfig.responseType === 'json') {
    api.interceptors.response.use(
      pipe(camelizeResponseHandler, ({ data }) => data),
      handleApiError
    );
  }

  return api.request<ResponseT>({
    url: config.url,
    data: config.body,
  });
};

export const merchantProfileApiFetch: ApiServicesT['merchantProfileApiFetch'] =
  async (config) => {
    return apiFetch({
      ...config,
      serviceUrl: ServiceUrlT.MERCHANT_PROFILE_SERVICE,
    });
  };
