<template lang="pug">
  v-main
    v-container(fluid pa-0 fill-height)
      v-layout
        //- The Form
        v-flex(xs8 pa-6)
          v-layout.white.py-6.form-wrapper
            v-flex(xs10 offset-xs1)
              h2.title.mb-2  Master Data Configuration

              div.mb-4
                Alert(
                  v-if="!canExport && !['INIT_INPROGRESS', 'INIT_FAILED', 'READONLY'].includes(state)"
                  type="warning"
                ) Export language is not set

                Alert(
                  v-if="showSavedNotExportedWarning"
                  type="warning"
                ) There is saved master data that has not been exported yet. Please send to export

                Alert(
                  v-if="showLastExportedMsg"
                  type="info"
                )
                  span The master data was sent to export on
                  span.time {{ exportLogs[0].createdAt | formatDateTime }}

              //- Initialised successfully or Save is in progress
              MasterdataForm(
                v-if="['DEFAULT', 'SAVE_INPROGRESS', 'EXPORT_INPROGRESS', 'READONLY'].includes(state)"
                :attributes="attributes"
                :values="values"
                :saved-values="savedValues"
                :size-attrs="sizeAttrs"
                :size-attrs-cache-map="sizeAttrsCacheMap"
                :size-attrs-loading="sizeAttrsLoading"
                :is-submitting="state === 'SAVE_INPROGRESS'"
                :is-exporting="state === 'EXPORT_INPROGRESS'"
                :is-readonly="state === 'READONLY'"
                :has-changes="hasChanges"
                :is-complete="isComplete"
                :can-export="canExport"
                :on-brand-change="onChangeofBrands"
                @save="$emit('save', values)"
                @clear="clear"
                @export="$emit('export')"
                @update:sizeSearchInput="$emit('update:sizeSearchInput', $event)"
              )

              //- Initialisation in progress
              v-layout(v-else-if="state === 'INIT_INPROGRESS'" justify-space-around)
                v-progress-circular.init-in-progress-icon(
                  :size="50"
                  color="primary"
                  indeterminate
                )

              //- Initialisation failed
              v-layout(v-else-if="state === 'INIT_FAILED'")
                v-flex.mt-12
                  Alert(type="error")
                    | Masterdata initialisation failed

        //- Summary/History sidebar
        v-flex(xs4)
          v-layout(fill-height)
            v-divider(vertical)
            v-flex.tab-items-container(grow)
              v-tabs(v-model="sidebarTab" grow slider-color="primary" slot="activator")
                v-tab Summary
                v-tab History

                v-tab-item
                  MasterdataSummaryContainer(
                    v-if="['DEFAULT', 'SAVE_INPROGRESS', 'EXPORT_INPROGRESS', 'READONLY'].includes(state)"
                    :readonly="['SAVE_INPROGRESS', 'EXPORT_INPROGRESS', 'READONLY'].includes(state)"
                    :attributes-maps="attributesMaps"
                    :values="values"
                  )
                v-tab-item
                  MasterdataHistory(:logs="exportLogs")
</template>

<script lang="ts">
/**
 * WARNING: Masterdata component is intentionally built
 * using "dirty" approaches.
 * It means that properties passed to children are mutable.
 * It was done for the sake of performance, because "pure"
 * approach is significantly slower (at least I was not be able
 * to achive good performance).
 */
import Vue from 'vue';
import { clone, equals, props } from 'ramda';
import MasterdataAttributes, {
  MasterdataAttributesMaps,
} from 'Api/models/MasterdataAttributes';
import MasterdataInterface from 'Api/models/Masterdata';
import MasterdataForm from './form/masterdata-form.vue';
import MasterdataSummaryContainer from './summary-container/masterdata-summary.container.vue';
import MasterdataHistory from './history/masterdata-history.vue';
import Alert from '../alert/alert.vue';

// Enumerate possible states
const INIT_INPROGRESS = 'INIT_INPROGRESS'; // empty screen + loading
const INIT_FAILED = 'INIT_FAILED'; // empty screen
const DEFAULT = 'DEFAULT'; // ready to work form
const SAVE_INPROGRESS = 'SAVE_INPROGRESS'; // readonly form + loading indicator
const READONLY = 'READONLY'; // readonly form + no buttons
const EXPORT_INPROGRESS = 'EXPORT_INPROGRESS'; // readonly form + loading indicator on export btn

export const STATE = {
  INIT_INPROGRESS,
  INIT_FAILED,
  DEFAULT,
  READONLY,
  SAVE_INPROGRESS,
  EXPORT_INPROGRESS,
};

export default Vue.extend({
  components: {
    Alert,
    MasterdataForm,
    MasterdataSummaryContainer,
    MasterdataHistory,
  },
  props: {
    state: String, // INIT_INPROGRESS | INIT_FAILED | DEFAULT | SAVE_INPROGRESS  | EXPORT_INPROGRESS
    attributes: Object,
    sizeAttrs: Array as () => Array<any>, // needed to know what to show in the dropdown
    sizeAttrsCacheMap: {
      type: Map as any,
      required: true,
    }, // includes sizeAttrs, previous values of sizeAttrs and also attributes for current values
    sizeAttrsLoading: Boolean,
    savedValues: Object, // A copy of masterdata that should be in sync with Backend
    canExport: Boolean,
    exportLogs: {
      type: Array as () => Array<any>,
      required: true,
    },
    showSavedNotExportedWarning: Boolean,
  },
  data() {
    return {
      values: clone(this.savedValues), // A copy of masterdata reflecting current state of the form
      sidebarTab: null,
    };
  },
  computed: {
    /**
     * Show the Last exported message if
     * - Save-And-Not-Exported warning is not displayed and
     * - there is at least something exported
     */
    showLastExportedMsg(): boolean {
      return !this.showSavedNotExportedWarning && !!this.exportLogs.length;
    },
    attributesMaps(): MasterdataAttributesMaps {
      // convert each array into Map
      const { seasons, brands, silhouettes, ownerBrands } = this
        .attributes as MasterdataAttributes;
      // @ts-ignore
      const seasonsMap = new Map(seasons.map(props(['label', 'name'])));
      // @ts-ignore
      const brandsMap = new Map(brands.map(props(['code', 'name'])));
      // prettier-ignore
      // @ts-ignore
      const silhouettesMap = new Map(silhouettes.map(props(['label', 'name'])));
      // @ts-ignore
      const ownerBrandsMap = new Map(ownerBrands?.map(props(['code', 'name'])));

      return {
        seasons: seasonsMap,
        brands: brandsMap,
        silhouettes: silhouettesMap,
        sizeCharts: this.sizeAttrsCacheMap,
        ownerBrands: ownerBrandsMap,
      } as MasterdataAttributesMaps;
    },
    isComplete(): boolean {
      const { brands, silhouettes, seasons, sizeCharts } = this
        .savedValues as MasterdataInterface;
      return !!(
        brands.length &&
        silhouettes.length &&
        seasons.length &&
        sizeCharts.length
      );
    },
    hasChanges(): boolean {
      const { seasons, brands, silhouettes, sizeCharts, ownerBrands } = this
        .values as MasterdataInterface;
      const {
        seasons: seasonsSaved,
        brands: brandsSaved,
        silhouettes: silhouettesSaved,
        sizeCharts: sizeChartsSaved,
        ownerBrands: ownerBrandsSaved,
      } = this.savedValues as MasterdataInterface;

      // Check first if the lists lengths are different
      if (
        seasons.length !== seasonsSaved.length ||
        brands.length !== brandsSaved.length ||
        silhouettes.length !== silhouettesSaved.length ||
        sizeCharts.length !== sizeChartsSaved.length ||
        ownerBrands.length !== ownerBrandsSaved.length
      ) {
        return true;
      }

      // So the lengths are the same.
      // Let's do a more complex check - check the values
      const seasonsChanged = !equals(seasons.sort(), seasonsSaved.sort());
      const brandsChanged = !equals(brands.sort(), brandsSaved.sort());
      const silhtsChanged = !equals(
        silhouettes.sort(),
        silhouettesSaved.sort()
      );
      const sizeChartsChanged = !equals(
        sizeCharts.sort(),
        sizeChartsSaved.sort()
      );
      const ownerBrandsChanged = !equals(
        ownerBrands?.sort(),
        ownerBrandsSaved?.sort()
      );

      return (
        seasonsChanged ||
        brandsChanged ||
        silhtsChanged ||
        sizeChartsChanged ||
        ownerBrandsChanged
      );
    },
  },
  watch: {
    savedValues(): void {
      this.values = clone(this.savedValues);
    },
  },
  methods: {
    clear(): void {
      this.values = clone(this.savedValues);
    },
    onChangeofBrands(): void {
      const { brands, ownerBrands } = this.values;
      const brandsSet = new Set(brands);
      const newOwnerBrands = ownerBrands.filter((ob) => brandsSet.has(ob));

      if (newOwnerBrands.length !== ownerBrands.length) {
        this.values = {
          ...this.values,
          ownerBrands: newOwnerBrands,
        };
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.v-tab {
  text-transform: capitalize;
}

.v-tab--active {
  color: #ff6900;
}

.init-in-progress-icon {
  margin: 80px 0 120px;
}

.form-wrapper {
  min-height: 400px;
}

.time {
  white-space: nowrap;
}

.tab-items-container {
  background-color: white;
}
</style>
