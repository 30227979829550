import appTranslations from 'react-packages/constants/app_en.json';
import { path } from 'ramda';

const useTranslation = (nameSpace = ''): { t: (key: string) => string } => {
  const nameSpaceObj = path(nameSpace.split('.'), appTranslations) as string;

  return {
    t: (key: string) => {
      let keyTranslation = path<string>(key.split('.'), nameSpaceObj);
      if (keyTranslation) {
        return keyTranslation;
      } else {
        // eslint-disable-next-line no-console
        console.warn('Missing translation for: ', `${nameSpace}.${key}`);
        return `${nameSpace}.${key}`;
      }
    },
  };
};

export default useTranslation;
