import { fromPairs, is, map, pipe, replace, toPairs } from 'ramda';

const decamelize = (str: string) =>
  replace(/([A-Z])/g, '_$1', str).toLowerCase();

export const decamelizeKeys = (obj: any): any => {
  if (is(Array, obj)) {
    return obj.map(decamelizeKeys);
  } else if (is(Object, obj)) {
    return pipe(
      toPairs,
      map(([k, v]) => [decamelize(k), decamelizeKeys(v)]),
      fromPairs
    )(obj);
  }
  return obj;
};
