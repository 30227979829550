<template lang="pug">
v-app(
    id="vue-carrier"
    :style="{background: 'white'}"
  )
    v-main
      v-container(fluid).pa-0
        //- Loading
        div.text-center.mt-2(v-if="isLoading")
          v-progress-circular(
            :size="50"
            color="primary"
            indeterminate
          )
        //- Return Address API error
        v-alert(
          v-else-if="isLoadingError"
          :value="true"
          type="error"
        ) Something went wrong while fetching Carrier Details information
        template(v-else)
          div(
            v-if="!preferredCarrier"
            class="d-flex mb-5 pa-3 mcp-admin-merchant-carrier-alert"
          )
            v-icon.mr-4.justify-center mdi-alert
            div.mt-2
              | <b>Not ready: </b>This partner does not have a carrier preference set yet.
              | If this is a hybrid partner (the merchant is using both ZFS and PF),
              | you will need to set their carrier for them. If this is not a hybrid partner,
              | please wait for the partner to set their carrier in zDirect.

          div(
            v-if="preferredCarrier && !configuredDeliveryProducts.length"
            class="d-flex mb-5 pa-3 mcp-admin-merchant-carrier-warning"
          )
            v-icon.mr-4.justify-center.blue--text.text--darken-3 mdi-information-outline
            div.mt-2
              | <b>Ready for carrier configuration:</b> You can begin adding the carrier configurations using the “Add delivery carrier configuration” or “Add return carrier configuration” buttons below. 
              | The partner has selected “Delivery Carrier: {{preferredCarrier}}" and  “Delivery method: {{preferredDeliveryProductsLabels}}" as their carrier preferences.

          div.grey.lighten-5.py-5.px-4.my-5
            h3.black--text
              | Delivery carrier
            div(v-if="!preferredCarrier").text-dark.mt-2
              | The partner has not yet selected their carrier preferences on zDirect.
              | Please wait before adding configuration for delivery carriers.
            div(v-if="preferredCarrier").text-dark.mt-2
              | Add configuration for delivery carriers.
              | For any carriers not listed, contact the OrderX team for overrides or exceptions.
            v-btn(v-if="!configuredDeliveryProducts.length && !showAddNewDeliveryMethod").mt-5.white--text.text-none(
              color="black"
              @click="showAddNewDeliveryMethod = !showAddNewDeliveryMethod"
              :loading="isDeliveryMethodSubmitting"
            ) Add delivery carrier configuration

            div(
              v-if="showInvalidConfigurationWarning && !isDeleteCarrierFlow"
              class="d-flex mb-5 pa-3 mcp-admin-merchant-carrier-alert"
            ).mt-4
              v-icon.mr-4.justify-center.text--darken-3 mdi-alert
              div.mt-1
                | This carrier configuration is outdated. Please delete it and select a new carrier.

            div(v-if="configuredDeliveryProducts.length").mt-4
              v-card(
                v-if="!showEditCarrierConfigurations"
                outlined
                tile
                rounded="sm"
              ).pa-4.rounded.mt-4
                div.d-flex.justify-space-between.align-center
                  div
                    div
                      span.text-subtitle-2.font-weight-bold.mr-2.black--text Delivery carrier:
                      span.text-body-2 {{configuredDeliveryProducts[0].carrier}}
                    div(v-if="configuredDeliveryParcelPerformMapping").mt-2
                      span.text-subtitle-2.font-weight-bold.mr-2.black--text Track & trace reference:
                      span(v-if="!showEditCarrierConfigurations").text-body-2 {{configuredDeliveryParcelPerformMapping.reference}}
                  div
                    v-btn.mt-5.mb-6.ml-2.text-none.rounded.font-weight-bold(
                        outlined
                        color="black"
                        @click="onEditCarrierSelectionsClick"
                      ) Edit
                    v-btn.mt-5.mb-6.ml-2.text-none.rounded.font-weight-bold(
                      outlined
                      color="black"
                      @click="onDeleteCarrier"
                    ) Delete
            
              div(v-if="showEditCarrierConfigurations").py-4
                div.mt-2.row
                  v-select.ml-4(
                    label="Select delivery carrier"
                    :items="carriersList"
                    v-model="selectedDeliveryCarrier"
                    @change="onDeliveryCarrierChange"
                    :style="{maxWidth: '40%'}"
                    disabled
                  )
                  v-select.ml-5(
                    label="Select track & trace reference"
                    :items="parcelPerformMappingsList"
                    v-model="selectedDeliveryParcelPerformMapping"
                    @change="onParcelPerformMappingChange"
                    :style="{maxWidth: '50%'}"
                  )
                v-btn.text-none.mt-4.rounded.font-weight-bold(
                  color="black"
                  outlined
                  @click="addNewDeliveryProductSelection"
                  :loading="isDeliveryMethodSubmitting"
                ) Save

            div(v-if="configuredDeliveryProducts.length").mt-4
              span.text-subtitle-2.font-weight-bold.black--text
                | Delivery methods
              div(v-if="!hasMandatoryDeliveryMethods && !showInvalidConfigurationWarning").d-flex.mb-2.pa-3.mcp-admin-merchant-carrier-alert.mt-4
                v-icon.mr-4.justify-center mdi-alert
                div
                  | Please configure at least one mandatory delivery method for this partner.
              DeliveryMethodCards(
                :list="configuredDeliveryProducts"
                :on-delete="deleteDeliveryProductSelection"
                :allowed-delivery-products="selectedCarrierConfiguration?.allowedDeliveryProducts || []"
              )
              v-btn.mt-5.white--text.text-none(
                v-if="!showAddNewDeliveryMethod && !showInvalidConfigurationWarning"
                color="black"
                @click="showAddNewDeliveryMethod = !showAddNewDeliveryMethod"
                :loading="isDeliveryMethodSubmitting"
              ) Add delivery method

            div(v-if="showAddNewDeliveryMethod").my-5
              h3.black--text.mb-4
                | Add delivery method
              div(v-if="configuredDeliveryProducts.length === 0").mt-1.row
                v-select.ml-4(
                  label="Select delivery carrier"
                  :items="carriersList"
                  v-model="selectedDeliveryCarrier"
                  @change="onDeliveryCarrierChange"
                  :style="{maxWidth: '40%'}"
                )
                v-select.ml-4(
                  label="Select track & trace reference"
                  :items="parcelPerformMappingsList"
                  v-model="selectedDeliveryParcelPerformMapping"
                  :style="{maxWidth: '40%'}"
                )
              div.row
                v-select.ml-4(
                  label="Delivery method"
                  :items="deliveryDestinationTypes"
                  v-model="selectedDeliveryProductId"
                  @change="setParcelServices"
                  :disabled="!deliveryDestinationTypes.length"
                  :style="{maxWidth: '40%'}"
                )
                v-select.ml-4(
                  label="Opt-in services"
                  :items="optionalCapabilities"
                  v-model="selectedOptionalCapabilities"
                  @change="isAddDeliveryCarrierSelectionsChanged = true"
                  :multiple="true"
                  :disabled="!optionalCapabilities.length"
                  :style="{maxWidth: '40%'}"
                )

              div(:style="{maxWidth: '100%'}").d-flex.flex-column.ml-4
                label.text-caption.v-label.theme--light.font-weight-light Parcel service
                div.mt-2 {{selectedParcelServices.join(', ') || '&#8212;'}}


              v-btn.mt-5.mb-6.ml-2.text-none.white--text(
                color="black"
                @click="addNewDeliveryProductSelection"
                :disabled="shouldDisableShipmentCarrierSubmit"
                :loading="isDeliveryMethodSubmitting"
              ) Save
          ReturnCarrier(
            :configuredReturnCarrierSelection="configuredReturnCarrierSelection"
            :availableReturnCarrierConfigurations="availableReturnCarrierConfigurations"
            :parcelPerformMappingsList="parcelPerformMappingsList"
            @add-return-carrier="addNewReturnCarrier"
          )
          CarrierDeleteWarningDialog(
            :showCarrierDeleteWarningDialog="showCarrierDeleteWarningDialog"
            :confirmCb="onDeleteCarrierConfirm"
            :cancelCb="onDeleteCarrierCancel"
            :loading="false"
          )
          v-snackbar(
            v-model="isDeleteCarrierFlow"
            color="dark"
            bottom
            :timeout="6000"
          )
            v-icon.mr-1.justify-center mdi-information-outline
            span Please add a new carrier with at least one mandatory delivery method, for the deletion to take place.
</template>
<script lang="ts">
import Vue from 'vue';
import {
  DeliveryProductRequestItemT,
  fetchParcelPerformMappings,
  fetchCarrierConfigurations,
  fetchDeliveryCarriersSelection,
  putDeliveryCarriersSelection,
  DeliveryCarrierSelectionRequestT,
  putReturnCarrierSelections,
  fetchReturnCarriersSelection,
  fetchCarrierInfo,
} from 'Api/endpoints/sales-channel/carrier.endpoint';
import {
  DELIVERY_DESTINATIONS,
  OPTIONAL_CAPABILITIES,
  PARCEL_SERVICES,
} from 'Common/constants/carriers.constant';
import DeliveryMethodCards from './delivery-method-cards.vue';
import ReturnCarrier from './return-carrier.vue';
import CarrierDeleteWarningDialog from './carrier-delete-warning-dialog.vue';
import { MerchantTypeT } from 'react-packages/apiSpecification/merchants';

export default Vue.extend({
  components: {
    DeliveryMethodCards,
    ReturnCarrier,
    CarrierDeleteWarningDialog,
  },
  props: {
    onSave: {
      type: Function,
      default: null,
      required: false, // Required only if not readonly
    },
    onError: {
      type: Function,
      default: null,
      required: false, // Required only if not readonly
    },
  },
  data() {
    return {
      isLoading: true,
      isLoadingError: false,
      isDeliveryMethodSubmitting: false,
      isReturnCarrierSubmitting: false,
      isAddDeliveryCarrierSelectionsChanged: false,
      showAddNewDeliveryMethod: false,
      showAddOrEditReturnCarrier: false,
      showEditCarrierConfigurations: false,
      showCarrierDeleteWarningDialog: false,
      isDeleteCarrierFlow: false,
      hasSelectedMandatoryDeliveryProduct: false,
      showInvalidConfigurationWarning: false,
      deliveryProductsMap: {
        HOME: 'Home',
        PICKUP_POINT: 'Pick up Point',
        PACKSTATION: 'Packstation',
      },
      preferredCarrier: '',
      preferredDeliveryProducts: [],
      deliverySpeed: 'STANDARD',
      deliveryProducts: [],
      // @ts-ignore
      ...this.mapState({
        merchantId: 'flow.specific.contract.data.merchantId', //maps state.todos to data.todoList
        salesChannelId: 'flow.specific.contract.data.salesChannelId', //maps state.todos to data.todoList
        contract: 'flow.specific.contract.data',
        requirements: 'flow.specific.requirements.data',
        requirementsLoading: 'flow.specific.requirements.loading',
      }),

      configuredDeliveryProducts: [],
      availableDeliveryCarrierConfigurations: [],
      availableReturnCarrierConfigurations: [],
      availableParcelPerformMappings: [],
      selectedCarrierConfiguration: null,
      selectedDeliveryCarrier: '',
      selectedDeliveryProductId: '',
      selectedDeliveryParcelPerformMapping: null,
      selectedReturnParcelPerformMapping: null,
      selectedOptionalCapabilities: [],
      selectedParcelServices: [],
      configuredDeliveryParcelPerformMapping: null,
      configuredReturnCarrierSelection: null,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ...this.mapState({
        merchantProfile: 'flow.general.merchant.data',
      }),
    };
  },
  computed: {
    carriersList() {
      return this.availableDeliveryCarrierConfigurations.map((config) => ({
        value: config.carrier.id,
        text: config.carrier.name,
      }));
    },
    hasMandatoryDeliveryMethods() {
      const mandatoryDeliveryProductsIds =
        this.selectedCarrierConfiguration?.allowedDeliveryProducts
          .filter((adp) => adp[this.getBusinessModelRule()] === 'MANDATORY')
          .map((adp) => adp.deliveryProduct.id);
      return mandatoryDeliveryProductsIds
        ? this.configuredDeliveryProducts.some((deliveryProduct) =>
            mandatoryDeliveryProductsIds.includes(deliveryProduct.id)
          )
        : false;
    },
    parcelPerformMappingsList() {
      return this.availableParcelPerformMappings
        .filter(
          (mapping) => mapping.id !== this.selectedDeliveryParcelPerformMapping
        )
        .map((mapping) => ({
          value: mapping,
          text: mapping.reference,
        }));
    },
    preferredDeliveryProductsLabels(): string {
      if (!this.preferredDeliveryProducts.length) {
        return 'Not defined';
      }
      return this.preferredDeliveryProducts
        .map((val) => this.deliveryProductsMap[val])
        .join(', ');
    },
    deliveryDestinationTypes() {
      if (!this.selectedDeliveryCarrier) return [];
      return this.selectedCarrierConfiguration.allowedDeliveryProducts.map(
        (adp) => ({
          value: adp.deliveryProduct.id,
          text: `${
            DELIVERY_DESTINATIONS[
              adp.deliveryProduct.deliveryDestinationType
            ] || adp.deliveryProduct.deliveryDestinationType
          } - ${this.capitalizeFirstLetter(
            adp[this.getBusinessModelRule()]
          )} (${this.getParcelServices(adp.deliveryProduct).join(', ')})`,
        })
      );
    },
    shouldDisableShipmentCarrierSubmit() {
      return (
        !this.isAddDeliveryCarrierSelectionsChanged ||
        this.isDeliveryMethodSubmitting ||
        !this.selectedDeliveryProductId ||
        (this.isDeleteCarrierFlow && !this.hasSelectedMandatoryDeliveryProduct)
      );
    },
    optionalCapabilities() {
      if (!this.selectedCarrierConfiguration) return [];
      const selectedAllowedDeliveryProduct =
        this.selectedCarrierConfiguration?.allowedDeliveryProducts.find(
          (adp) => adp.deliveryProduct.id === this.selectedDeliveryProductId
        );

      if (!this.selectedDeliveryProductId) return [];

      return selectedAllowedDeliveryProduct.deliveryProduct.optionalCapabilities.map(
        (optionalCapability) => {
          return {
            value: optionalCapability,
            text:
              OPTIONAL_CAPABILITIES[optionalCapability] || optionalCapability,
          };
        }
      );
    },
  },
  mounted() {
    const merchantType =
      this.merchantProfile.merchantType === MerchantTypeT.CONNECTED_RETAIL
        ? 'CONNECTED_RETAIL'
        : 'PARTNER_PROGRAM';
    Promise.all([
      fetchCarrierConfigurations(
        this.merchantId,
        this.salesChannelId,
        merchantType
      ),
      fetchParcelPerformMappings(),
      fetchDeliveryCarriersSelection(this.merchantId, this.salesChannelId),
      fetchReturnCarriersSelection(this.merchantId, this.salesChannelId),
      fetchCarrierInfo(this.merchantId, this.salesChannelId),
      fetchCarrierConfigurations(
        this.merchantId,
        this.salesChannelId,
        merchantType,
        'return'
      ),
    ])
      .then(
        ([
          deliveryCarrierConfigurations,
          availableParcelPerformMappings,
          configuredDeliveryCarriersSelection,
          configuredReturnCarrierSelection,
          carrierInfo,
          returnCarrierConfigurations,
        ]) => {
          if (carrierInfo) {
            this.preferredCarrier = carrierInfo.shipmentCarrier;
            this.preferredDeliveryProducts = carrierInfo.deliveryProducts;
          }

          this.availableDeliveryCarrierConfigurations =
            deliveryCarrierConfigurations;
          this.availableReturnCarrierConfigurations =
            returnCarrierConfigurations;
          this.availableParcelPerformMappings = availableParcelPerformMappings;
          this.configuredDeliveryProducts =
            configuredDeliveryCarriersSelection === null
              ? []
              : configuredDeliveryCarriersSelection.deliveryProducts;
          this.configuredDeliveryParcelPerformMapping =
            configuredDeliveryCarriersSelection === null
              ? null
              : configuredDeliveryCarriersSelection.parcelPerformReference;
          this.configuredReturnCarrierSelection =
            configuredReturnCarrierSelection;
          if (this.configuredDeliveryProducts.length > 0) {
            this.selectedCarrierConfiguration =
              this.availableDeliveryCarrierConfigurations.find(
                (config) =>
                  config.carrier.id ===
                  this.configuredDeliveryProducts[0].carrierId
              );
            if (this.selectedCarrierConfiguration) {
              this.selectedDeliveryCarrier =
                this.selectedCarrierConfiguration.carrier.id;
            } else {
              this.showInvalidConfigurationWarning = true;
            }
          }
        }
      )
      .then(() => {
        this.isLoading = false;
      })
      .catch(() => {
        this.isLoading = false;
        this.isLoadingError = true;
      });
  },
  methods: {
    setParcelServices() {
      this.isAddDeliveryCarrierSelectionsChanged = true;
      const selectedADP =
        this.selectedCarrierConfiguration.allowedDeliveryProducts.find(
          (adp) => adp.deliveryProduct.id === this.selectedDeliveryProductId
        );

      if (selectedADP[this.getBusinessModelRule()] === 'MANDATORY') {
        this.hasSelectedMandatoryDeliveryProduct = true;
      }
      this.selectedParcelServices = this.getParcelServices(
        selectedADP.deliveryProduct
      );
    },
    getParcelServices(deliveryProduct) {
      return deliveryProduct
        ? deliveryProduct.capabilities.map(
            (capability) => PARCEL_SERVICES[capability] || capability
          )
        : [];
    },
    getBusinessModelRule() {
      return this.merchantProfile.merchantType ===
        MerchantTypeT.CONNECTED_RETAIL
        ? 'connectedRetailRule'
        : 'partnerProgramRule';
    },
    onDeleteCarrierCancel() {
      this.showCarrierDeleteWarningDialog = false;
    },
    capitalizeFirstLetter(string) {
      const lowercaseString = string.toLowerCase();
      return lowercaseString.charAt(0).toUpperCase() + lowercaseString.slice(1);
    },
    onDeleteCarrierConfirm() {
      this.configuredDeliveryProducts = [];
      this.showCarrierDeleteWarningDialog = false;
      this.isDeleteCarrierFlow = true;
    },
    onDeleteCarrier() {
      if (this.configuredDeliveryProducts.length > 0) {
        this.showCarrierDeleteWarningDialog = true;
      }
    },
    getNewDeliveryMethodsSelectionsList(
      configuredDeliveryProducts
    ): DeliveryProductRequestItemT[] {
      const list = configuredDeliveryProducts.map(
        ({ id, configuredOptionalDeliveryCapabilities }) => ({
          id,
          configuredOptionalDeliveryCapabilities,
        })
      );
      if (this.selectedDeliveryProductId) {
        list.push({
          id: this.selectedDeliveryProductId,
          configuredOptionalDeliveryCapabilities:
            this.selectedOptionalCapabilities,
        });
      }
      return list;
    },
    onEditCarrierSelectionsClick() {
      this.showEditCarrierConfigurations = true;
      this.selectedDeliveryParcelPerformMapping =
        this.configuredDeliveryParcelPerformMapping;
    },
    onParcelPerformMappingChange(mapping) {
      this.selectedDeliveryParcelPerformMapping = mapping;
    },
    updateDeliveryProductsSelectionsList(
      deliveryProducts,
      parcelPerformReferenceId
    ) {
      const payload: DeliveryCarrierSelectionRequestT = {
        deliveryProducts:
          this.getNewDeliveryMethodsSelectionsList(deliveryProducts),
        parcelPerformReferenceId: parcelPerformReferenceId,
      };

      return putDeliveryCarriersSelection(
        this.merchantId,
        this.salesChannelId,
        payload
      );
    },
    addNewDeliveryProductSelection(): void {
      this.isDeliveryMethodSubmitting = true;
      const parcelPerformReferenceId = this.selectedDeliveryParcelPerformMapping
        ? this.selectedDeliveryParcelPerformMapping.id
        : this.configuredDeliveryParcelPerformMapping.id;
      this.updateDeliveryProductsSelectionsList(
        this.configuredDeliveryProducts,
        parcelPerformReferenceId
      )
        .then(() => {
          this.isDeliveryMethodSubmitting = false;
          this.isAddDeliveryCarrierSelectionsChanged = false;
          this.onSave();
        })
        .catch(() => {
          this.isDeliveryMethodSubmitting = false;
          this.onError();
        });
    },
    addNewReturnCarrier(payload): void {
      this.isReturnCarrierSubmitting = true;
      putReturnCarrierSelections(this.merchantId, this.salesChannelId, payload)
        .then(() => {
          this.isReturnCarrierSubmitting = false;
          this.onSave();
        })
        .catch(() => {
          this.isReturnCarrierSubmitting = false;
          this.onError();
        });
    },
    deleteDeliveryProductSelection(id) {
      const list = this.configuredDeliveryProducts.filter(
        (crr) => crr.id !== id
      );

      this.updateDeliveryProductsSelectionsList(
        list,
        this.configuredDeliveryParcelPerformMapping.id
      )
        .then(() => this.onSave())
        .catch(() => this.onError());
    },
    onDeliveryCarrierChange(id) {
      this.selectedCarrierConfiguration =
        this.availableDeliveryCarrierConfigurations.find(
          (config) => config.carrier.id === id
        );
      this.selectedDeliveryParcelPerformMapping =
        this.availableDeliveryCarrierConfigurations.find(
          (config) => config.carrier.id === id
        ).carrier.parcelPerformReference;
      this.isAddDeliveryCarrierSelectionsChanged = true;
      this.selectedDeliveryProductId = '';
      this.selectedParcelServices = [];
    },
    onReturnCarrierChange(id) {
      this.selectedReturnCarrier = id;
      this.selectedReturnParcelPerformMapping =
        this.availableReturnCarrierConfigurations.find(
          (config) => config.carrier.id === id
        ).carrier.parcelPerformReference;
    },
  },
});
</script>
<style lang="scss" scoped></style>
