import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
} from '@partner-tech/pax-ui-kit';
import { useHistory } from 'react-packages/hooks/useHistory';
import useTranslation from 'react-packages/hooks/useTranslation';

const AppError = (): JSX.Element => {
  const { t: tErrors } = useTranslation('ERRORS');
  const { t: tCommon } = useTranslation('COMMON');
  const history = useHistory();

  const reload = () => {
    history.location.reload();
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" color="error" gutterBottom>
          {tErrors('SECTION_LOAD')}
        </Typography>
        <Box display="flex" justifyContent="flex-end" mt={4}>
          <Button variant="contained" color="primary" onClick={reload}>
            {tCommon('RELOAD_PAGE')}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default AppError;
