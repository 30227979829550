import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { merchantProfileApiFetch } from 'react-packages/utils/apiFetch';
import { LegalEntityResponseT } from 'react-packages/apiSpecification';

export const queryKeyword = 'LEGAL_ENTITY';

// ========= QUERY =========
export function useLegalEntityQuery(
  legalEntityId?: string
): UseQueryResult<LegalEntityResponseT> {
  const fetchData = () =>
    merchantProfileApiFetch<LegalEntityResponseT>({
      url: `/legal-entities/${legalEntityId}`,
    });

  return useQuery({
    queryKey: [queryKeyword, legalEntityId],
    queryFn: fetchData,
    enabled: Boolean(legalEntityId),
  });
}
