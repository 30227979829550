import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { merchantProfileApiFetch } from 'react-packages/utils/apiFetch';
import { CountryCodeT } from 'react-packages/apiSpecification';

export enum TinTypeT {
  PERSONAL = 'PERSONAL',
  LOCAL = 'LOCAL',
}

export interface LegalEntityTinT {
  tin: string;
  countryCode: CountryCodeT;
  type: TinTypeT;
}

export const queryKeyword = 'LEGAL_ENTITY_TINS';

// ========= QUERY =========
export function useTinsQuery(
  legalEntityId?: string
): UseQueryResult<LegalEntityTinT[]> {
  const fetchData = () =>
    merchantProfileApiFetch<LegalEntityTinT[]>({
      url: `/legal-entities/${legalEntityId}/tins`,
    });

  return useQuery({
    queryKey: [queryKeyword, legalEntityId],
    queryFn: fetchData,
    enabled: Boolean(legalEntityId),
  });
}
