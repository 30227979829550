import axios from 'axios';
import {
  camelizeResponseHandler,
  decamelizeRequestHandler,
} from 'Api/api-mutations';
import ErrorHandler from 'Api/api-errors-handler';
import { pipe, path, prop } from 'ramda';

export interface ShipmentCarrierT {
  code: string;
  name: string;
}

export interface AvailableCarrierResponseItemT {
  id: string;
  countryCode: string;
  carrier: string;
  deliveryDestinationType: string;
  capabilities: string[];
  optionalCapabilities: string[];
}

export interface ParcelPerformMappingT {
  id: string;
  reference: string;
}

interface DeliveryProductT {
  id: string;
  countryCode: string;
  carrier: string;
  deliveryDestinationType: string;
  capabilities: string[];
  configuredOptionalDeliveryCapabilities: string[];
  stockLocationId: string;
  carrierId: string;
}

interface DeliveryCarrierSelectionT {
  deliveryProducts: DeliveryProductT[];
  parcelPerformReference: ParcelPerformMappingT;
}

export interface DeliveryCarrierSelectionRequestT {
  deliveryProducts: DeliveryProductRequestItemT[];
  parcelPerformReferenceId: string;
}

export interface CarrierT {
  id: string;
  name: string;
  abbreviation: string;
  countryCode: string;
  parcelPerformReference: ParcelPerformMappingT;
  requiredCustomerData: string[];
  trackingUrlTemplate: string;
  isVisible: boolean;
  isAllowedForConnectedRetailReturns: boolean;
  isAllowedForPartnerProgramReturns: boolean;
}

interface ReturnCarrierSelectionT {
  carrier: {
    id: string;
    name: string;
    abbreviation: string;
    countryCode: string;
    parcelPerformReference: ParcelPerformMappingT;
    requiredCustomerData: string[];
    trackingUrlTemplate: string;
    isVisible: boolean;
    isAllowedForConnectedRetailReturns: boolean;
    isAllowedForPartnerProgramReturns: boolean;
  };
  parcelPerformReference: ParcelPerformMappingT;
}

interface ReturnCarrierSelectionRequestT {
  carrierId: string;
  parcelPerformReferenceId: string;
}

export interface ConfiguredCarrierResponseItemT
  extends Omit<AvailableCarrierResponseItemT, 'optionalCapabilities'> {
  configuredOptionalDeliveryCapabilities: string[];
}

export interface DeliveryProductRequestItemT {
  id: string;
  configuredOptionalDeliveryCapabilities: string[];
}

type DeliveryDestinationTypeT = 'HOME' | 'PICKUP_POINT' | 'PACKSTATION';

export interface CarrierInfoT {
  shipmentCarrier: string;
  deliverySpeed: 'STANDARD';
  deliveryProducts: DeliveryDestinationTypeT[];
}

export interface StocksRequirementsResponseT {
  carrierProductsSpecified: boolean;
  carrierProductsSynced: boolean;
}

export interface CarrierConfigurationT {
  carrier: CarrierT;
  allowedDeliveryProducts: {
    id: string;
    deliverProduct: DeliveryProductT;
    allowedOptionalCapabilites: string[];
    connectedRetailRule: boolean;
    partnerProgramRule: boolean;
  }[];
}

const carriersApi = axios.create({
  baseURL: '/api/merchant-profile/shipment-carriers',
});

carriersApi.interceptors.request.use(decamelizeRequestHandler);
carriersApi.interceptors.response.use(
  pipe(camelizeResponseHandler, path(['data'])),
  ErrorHandler
);

const carrierInfoApi = axios.create({
  baseURL: '/api/merchant-profile/merchants',
});
carrierInfoApi.interceptors.request.use(decamelizeRequestHandler);
carrierInfoApi.interceptors.response.use(
  pipe(camelizeResponseHandler, path(['data'])),
  ErrorHandler
);

const availableCarriersApi = axios.create({
  baseURL: '/api/carrier-products/carrier-products/sales-channels',
});

availableCarriersApi.interceptors.request.use(decamelizeRequestHandler);
availableCarriersApi.interceptors.response.use(
  pipe(camelizeResponseHandler, path(['data'])),
  ErrorHandler
);

const configuredCarriersApi = axios.create({
  baseURL: '/api/carrier-products/merchants',
});
configuredCarriersApi.interceptors.request.use(decamelizeRequestHandler);
configuredCarriersApi.interceptors.response.use(
  pipe(camelizeResponseHandler, path(['data'])),
  ErrorHandler
);

const carrierProductsApi = axios.create({
  baseURL: '/api/carrier-products',
});
carrierProductsApi.interceptors.request.use(decamelizeRequestHandler);
carrierProductsApi.interceptors.response.use(
  pipe(camelizeResponseHandler, path(['data'])),
  ErrorHandler
);

export function fetchCarriers(
  salesChannelId: string
): Promise<ShipmentCarrierT[]> {
  return (
    carriersApi
      .get(`/?sales_channel_id=${salesChannelId}`)
      // @ts-ignore
      .then(prop('items')) as Promise<ShipmentCarrierT[]>
  );
}

export function fetchCarrierByCode(code: string): Promise<ShipmentCarrierT> {
  return carriersApi.get(`/${code}`);
}

export function fetchCarrierInfo(
  merchantId: string,
  salesChannelId: string
): Promise<CarrierInfoT | null> {
  return carrierInfoApi
    .get(`${merchantId}/sales-channels/${salesChannelId}/carrier-info`)
    .catch((err) => {
      return err.response?.status === 404 ? null : Promise.reject(err);
    }) as Promise<CarrierInfoT | null>;
}

export function putCarrierInfo(
  merchantId: string,
  salesChannelId: string,
  data: CarrierInfoT
): Promise<CarrierInfoT> {
  return carrierInfoApi.put(
    `${merchantId}/sales-channels/${salesChannelId}/carrier-info`,
    data
  );
}

export function fetchAvailableCarriersForSalesChannel(
  salesChannelId: string
): Promise<AvailableCarrierResponseItemT[]> {
  return (
    availableCarriersApi
      .get(`/${salesChannelId}`)
      // @ts-ignore
      .then(prop('items'))
  );
}

export function fetchConfiguredCarriers(
  merchantId: string,
  salesChannelId: string
): Promise<ConfiguredCarrierResponseItemT[] | null> {
  return (
    configuredCarriersApi
      .get(`${merchantId}/sales-channels/${salesChannelId}/delivery-products`)
      // @ts-ignore
      .then(prop('items'))
      .catch((err) => {
        return err.response?.status === 404 ? null : Promise.reject(err);
      }) as Promise<ConfiguredCarrierResponseItemT[] | null>
  );
}

export function fetchDeliveryCarriersSelection(
  merchantId: string,
  salesChannelId: string
): Promise<DeliveryCarrierSelectionT | null> {
  return (
    configuredCarriersApi
      .get(`${merchantId}/delivery-carrier-selections/${salesChannelId}`)
      // @ts-ignore
      .catch((err) => {
        return err.response?.status === 404 ? null : Promise.reject(err);
      }) as Promise<DeliveryCarrierSelectionT | null>
  );
}

export function putDeliveryCarriersSelection(
  merchantId: string,
  salesChannelId: string,
  data: DeliveryCarrierSelectionRequestT
): Promise<void> {
  return configuredCarriersApi.put(
    `${merchantId}/delivery-carrier-selections/${salesChannelId}`,
    data
  );
}

export function fetchReturnCarriersSelection(
  merchantId: string,
  salesChannelId: string
): Promise<ReturnCarrierSelectionT | null> {
  return (
    configuredCarriersApi
      .get(`${merchantId}/return-carrier-selections/${salesChannelId}`)
      // @ts-ignore
      .catch((err) => {
        return err.response?.status === 404 ? null : Promise.reject(err);
      }) as Promise<ReturnCarrierSelectionT | null>
  );
}

export function putReturnCarrierSelections(
  merchantId: string,
  salesChannelId: string,
  data: ReturnCarrierSelectionRequestT
): Promise<void> {
  return configuredCarriersApi.put(
    `${merchantId}/return-carrier-selections/${salesChannelId}`,
    data
  );
}

export function putConfiguredCarriers(
  merchantId: string,
  salesChannelId: string,
  data: DeliveryProductRequestItemT[]
): Promise<void> {
  return configuredCarriersApi.put(
    `${merchantId}/sales-channels/${salesChannelId}/delivery-products`,
    { items: data }
  );
}

export function fetchCarriersRequirements(
  merchantId: string,
  salesChannelId: string
): Promise<StocksRequirementsResponseT> {
  return configuredCarriersApi.get(
    `${merchantId}/sales-channels/${salesChannelId}/requirements`
  );
}

export function fetchParcelPerformMappings(): Promise<
  ParcelPerformMappingT[] | null
> {
  return (
    carrierProductsApi
      .get('parcel-perform-references')
      // @ts-ignore
      .then(prop('items'))
      .catch((err) => {
        return err.response?.status === 404 ? null : Promise.reject(err);
      }) as Promise<ParcelPerformMappingT[] | null>
  );
}

export function fetchCarrierConfigurations(
  merchantId: string,
  salesChannelId: string,
  businessModel: string,
  shipmentType = 'delivery'
): Promise<CarrierConfigurationT[] | null> {
  return (
    carrierProductsApi
      .get(
        `carrier-configurations?sales_channel_id=${salesChannelId}&merchant_id=${merchantId}&is_carrier_mpc_visible=true&shipment_type=${shipmentType}&business_model=${businessModel}&is_carrier_mpc_visible=true`
      )
      // @ts-ignore
      .then(prop('items'))
      .catch((err) => {
        return err.response?.status === 404 ? null : Promise.reject(err);
      }) as Promise<CarrierConfigurationT[] | null>
  );
}
