import { mcb } from 'mcb';
import wrapVue from 'Common/wrap-vue.converter';
import coreModule from 'Common/core.module';
import { Contract } from 'Api/models/Contract';
import { ContractActions } from 'Merchant/common/state/actions/contract.actions';
import { Store } from 'redux';
import { FlowState } from 'Merchant/common/state/flow.state';
import { FlowStateSelector } from 'Merchant/common/state/selectors/flow-state.selector';
import { ContractContainer } from './contract.container';
import { MCP_ADMIN_ROLES } from 'Common/constants/roles.constant';
import { ContractConfigurationComponent } from './components/configuration/configuration.component';
import { ContractConfigurationFormComponent } from './components/configuration-form/configuration-form.component';
import { ContractConfigurationReadonlyComponent } from './components/configuration-readonly/configuration-readonly.component';
import { ContractHeaderComponent } from './components/contract-header/contract-header.component';
import { ContractNavigationComponent } from './components/navigation/contract-navigation.component';
import ConfigurationDIFormComponent from './components/configuration-di-form/configuration-di-form.vue';
import ConfigurationDIReadonlyComponent from './components/configuration-di-readonly/configuration-di-readonly.vue';
import CarrierComponent from './components/contract-carrier/carrier.container.vue';
import CarrierComponentNew from './components/contract-carrier-new/carrier.container.vue';
import RowComponent from './row/contract-row.container.vue';
import CompanyDataComponent from './components/company-data/company-data.container.vue';
import ApprovalComponent from './components/contract-approval/contract-approval.container.vue';
import OverviewComponent from './overview/contract-overview.container.vue';
import { canEnableNewShipmentCarrierView } from '@/feature-flags';

export default angular
  .module('mcp.admin.merchant.contract', [coreModule.name])

  .config([
    '$stateProvider',
    ($stateProvider: angular.ui.IStateProvider): void => {
      $stateProvider
        .state('mcp.admin.merchant.contract', {
          url: '/contracts/:contractId?',
          template: `<mcp-admin-contract-container 
                      ng-if="merchantCtrl.merchant"
                      merchant="merchantCtrl.merchant" 
                      merchant-status="merchantCtrl.merchantStatus"
                    ></mcp-admin-contract-container>`,
          firewalls: {
            iam: {
              roles: [
                MCP_ADMIN_ROLES.INTEGRATION_KEY_ACCOUNT_MANAGER,
                MCP_ADMIN_ROLES.STOCK_MANAGER,
                MCP_ADMIN_ROLES.SUPER_ADMIN,
                MCP_ADMIN_ROLES.READ_ONLY,
                MCP_ADMIN_ROLES.OPERATIONS_MANAGER,
              ],
            },
          },
        } as mcb.firewall.IMcbState)

        .state('mcp.admin.merchant.contract.overview', {
          url: '/overview',
          template: `<mp-contract-overview
                      on-launch-cb="contractCtrl.onLaunch"
                      on-launch-error-cb="contractCtrl.onLaunchError"/>`,
          firewalls: {
            fallback: {
              state: 'mcp.admin.merchant.contract.approval',
              roles: [MCP_ADMIN_ROLES.FINANCIAL_APPROVER],
            },
            iam: {
              scopes: [
                MCP_ADMIN_ROLES.INTEGRATION_KEY_ACCOUNT_MANAGER,
                MCP_ADMIN_ROLES.STOCK_MANAGER,
                MCP_ADMIN_ROLES.SUPER_ADMIN,
                MCP_ADMIN_ROLES.READ_ONLY,
                MCP_ADMIN_ROLES.OPERATIONS_MANAGER,
              ],
            },
          },
        } as mcb.firewall.IMcbState)

        .state('mcp.admin.merchant.contract.approval', {
          url: '/contract-approval',
          template: `<mp-contract-approval
                        merchant="contractCtrl.merchant"
                        contract="contractCtrl.contract"
                        on-approve-cb="contractCtrl.onApprove"
                        on-reject-cb="contractCtrl.onReject"
                      ></mp-contract-approval>`,
          firewalls: {
            fallback: {
              state: 'mcp.admin.merchant.contract.overview',
              roles: [
                MCP_ADMIN_ROLES.INTEGRATION_KEY_ACCOUNT_MANAGER,
                MCP_ADMIN_ROLES.STOCK_MANAGER,
                MCP_ADMIN_ROLES.SUPER_ADMIN,
                MCP_ADMIN_ROLES.READ_ONLY,
                MCP_ADMIN_ROLES.OPERATIONS_MANAGER,
              ],
            },
            iam: {
              roles: [MCP_ADMIN_ROLES.FINANCIAL_APPROVER],
            },
          },
        } as mcb.firewall.IMcbState)

        .state('mcp.admin.merchant.contract.company-data', {
          url: '/contract-company-details',
          template: `<mp-contract-company-data
                      merchant="contractCtrl.merchant"
                      contract="contractCtrl.contract"
                      on-save-success="contractCtrl.onSave"
                    ></mp-contract-company-data>`,
          firewalls: {
            iam: {
              roles: [
                MCP_ADMIN_ROLES.INTEGRATION_KEY_ACCOUNT_MANAGER,
                MCP_ADMIN_ROLES.STOCK_MANAGER,
                MCP_ADMIN_ROLES.SUPER_ADMIN,
              ],
            },
          },
        } as mcb.firewall.IMcbState)

        .state('mcp.admin.merchant.contract.carrier', {
          url: '/carrier-details',
          template: `<mp-contract-carrier
                        on-save="ctrl.onSaveCarrier"
                        on-error="ctrl.onSaveCarrierError"
                      />`,
          controller: [
            '$ngRedux',
            'mcbToast',
            'mcpAdminContractActions',
            'mcpAdminFlowStateSelector',
            function (
              store: Store<FlowState>,
              toast: mcb.IToast,
              contractActions: ContractActions,
              flowSelector: FlowStateSelector
            ) {
              const state: FlowState = store.getState();
              let contract: Contract = flowSelector.getFlowContract(state);

              this.onSaveCarrier = (): void => {
                store
                  // @ts-ignore
                  .dispatch(contractActions.onSaveMarketData())
                  .then(() => {
                    const { name: retailer } = contract.retailer;
                    const { countryName } = contract.salesChannel;
                    toast.success(
                      `Carrier details for for ${retailer} - ${countryName} successfully saved`
                    );
                  })
                  .catch(() => toast.error('Something went wrong!'));
              };

              this.onSaveCarrierError = (): void => {
                toast.error('Something went wrong!');
              };
            },
          ],
          controllerAs: 'ctrl',
          firewalls: {
            iam: {
              roles: [
                MCP_ADMIN_ROLES.INTEGRATION_KEY_ACCOUNT_MANAGER,
                MCP_ADMIN_ROLES.STOCK_MANAGER,
                MCP_ADMIN_ROLES.SUPER_ADMIN,
              ],
            },
          },
        } as mcb.firewall.IMcbState)

        .state('mcp.admin.merchant.contract.configuration', {
          url: '/configuration',
          template: `<mcp-admin-contract-configuration 
                      contract="contractCtrl.contract"
                      merchant="contractCtrl.merchant"
                      retailer-name="contractCtrl.retailerName"
                      ng-if="contractCtrl.contract"
                      ></mcp-admin-contract-configuration>`,
          firewalls: {
            iam: {
              roles: [
                MCP_ADMIN_ROLES.INTEGRATION_KEY_ACCOUNT_MANAGER,
                MCP_ADMIN_ROLES.STOCK_MANAGER,
                MCP_ADMIN_ROLES.SUPER_ADMIN,
                MCP_ADMIN_ROLES.READ_ONLY,
                MCP_ADMIN_ROLES.OPERATIONS_MANAGER,
              ],
            },
          },
        } as mcb.firewall.IMcbState)

        .state('mcp.admin.merchant.contract.row', {
          url: '/row',
          template: `<mcp-contract-row 
                      merchant="contractCtrl.merchant"
                      contract="contractCtrl.contract"
                      contracts="contractCtrl.channelContracts"
                      current-role="contractCtrl.currentRole"
                      ></mcp-contract-row>`,
          firewalls: {
            iam: {
              roles: [
                MCP_ADMIN_ROLES.INTEGRATION_KEY_ACCOUNT_MANAGER,
                MCP_ADMIN_ROLES.STOCK_MANAGER,
                MCP_ADMIN_ROLES.SUPER_ADMIN,
              ],
            },
          },
        } as mcb.firewall.IMcbState);
    },
  ])
  .component('mcpAdminContractContainer', ContractContainer.Factory())
  .component(
    'mpContractOverview',
    wrapVue(OverviewComponent, 'mpContractOverview')
  )
  .component(
    'mpContractApproval',
    wrapVue(ApprovalComponent, 'mpContractApproval')
  )
  .component(
    'mcpAdminContractConfiguration',
    ContractConfigurationComponent.Factory()
  )
  .component(
    'mcpAdminContractConfigurationReadonly',
    ContractConfigurationReadonlyComponent.Factory()
  )
  .component(
    'mcpAdminContractConfigurationForm',
    ContractConfigurationFormComponent.Factory()
  )
  .component(
    'mcpAdminContractNavigation',
    ContractNavigationComponent.Factory()
  )
  .component(
    'mcpConfigurationDiForm',
    wrapVue(ConfigurationDIFormComponent, 'mcpConfigurationDiForm')
  )
  .component(
    'mpContractCarrier',
    wrapVue(
      canEnableNewShipmentCarrierView()
        ? CarrierComponentNew
        : CarrierComponent,
      'mpContractCarrier'
    )
  )
  .component(
    'mcpConfigurationDiReadonly',
    wrapVue(ConfigurationDIReadonlyComponent, 'mcpConfigurationDiReadonly')
  )
  .component('mcpContractRow', wrapVue(RowComponent, 'mcpContractRow'))
  .component('mcpAdminContractHeader', ContractHeaderComponent.Factory())
  .component(
    'mpContractCompanyData',
    wrapVue(CompanyDataComponent, 'mpContractCompanyData')
  );
