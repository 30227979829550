import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { merchantProfileApiFetch } from 'react-packages/utils/apiFetch';
import { MerchantT } from 'react-packages/apiSpecification';

export const queryKeyword = 'MERCHANT';

// ========= QUERY =========
export function useMerchantQuery(
  merchantId?: string
): UseQueryResult<MerchantT> {
  const fetchData = () => {
    return merchantProfileApiFetch<MerchantT>({
      url: `/merchants/${merchantId}`,
    });
  };

  return useQuery({
    queryKey: [queryKeyword, merchantId],
    queryFn: fetchData,
    enabled: Boolean(merchantId),
  });
}
