import axios from 'axios';
import { pipe } from 'ramda';
import SizeChart from 'Api/models/SizeChart';
import SizeChartGrid from 'Api/models/SizeChartGrid';
import Masterdata from 'Api/models/Masterdata';
import MasterdataExportLang from 'Api/models/MasterdataExportLang';
import MasterdataLogItem from 'Api/models/MasterdataLogItem';
import ErrorHandler from 'Api/api-errors-handler';
import {
  camelizeResponseHandler,
  decamelizeRequestHandler,
} from 'Api/api-mutations';
import {
  MD_LOG_ACTION_EXPORT,
  MD_LOG_ACTION_SAVE,
} from 'Common/constants/masterdata.constant';

const attrApi = axios.create({ baseURL: 'api/masterdata/attributes/' });
attrApi.interceptors.request.use(decamelizeRequestHandler);
attrApi.interceptors.response.use(
  pipe(camelizeResponseHandler, ({ data }) => data.items),
  ErrorHandler
);

const sizeChartApi = axios.create({ baseURL: 'api/masterdata/attributes/' });
sizeChartApi.interceptors.request.use(decamelizeRequestHandler);
sizeChartApi.interceptors.response.use(
  pipe(camelizeResponseHandler, ({ data }) => data),
  ErrorHandler
);

const masterdataApi = axios.create({ baseURL: 'api/masterdata/merchants/' });
masterdataApi.interceptors.request.use(decamelizeRequestHandler);
masterdataApi.interceptors.response.use(
  pipe(camelizeResponseHandler, ({ data }) => data),
  ErrorHandler
);

export const emptyMasterdata: Masterdata = {
  brands: [],
  seasons: [],
  silhouettes: [],
  sizeCharts: [],
  ownerBrands: [],
};

export const emptyMDExportLanguage: MasterdataExportLang = {
  exportLanguage: null,
};

// TODO: Add return type Promise<Season[]>
function fetchSeasons() {
  return attrApi.get('seasons');
}

// TODO: Add return type Promise<Brand[]>
function fetchBrands() {
  return attrApi.get('brands');
}

// TODO: Add return type Promise<Silhouette[]>
function fetchSilhouettes() {
  return attrApi.get('silhouettes');
}

// TODO: add return type Promise<MasterdataAttributes>
export function fetchAttribues() {
  return Promise.all([fetchSeasons(), fetchBrands(), fetchSilhouettes()]).then(
    ([seasons, brands, silhouettes]) => ({
      seasons,
      brands,
      silhouettes,
      ownerBrands: brands,
    })
  );
}

let sizechartsAttrsReq;

// TODO: Add return type Promise<SizeChart[]>
export function fetchSizechartsAttributes(q: string) {
  return attrApi.get('size-charts', {
    params: { q },
    headers: { version: 2 },
  });
}

/**
 * Fetch size charts grid (detailed size chart information)
 * @param id  Size chart id
 */
export function fetchSizeChart(id: string): Promise<SizeChartGrid> {
  return sizeChartApi.get(`size-charts/${id}`, {
    headers: { version: 2 },
  });
}

// TODO: add return type Promise<Masterdata>
// Load draft version of masterdata (not the confirmed/exported one)
export function fetchMasterdata(businessPartnerId: string) {
  return masterdataApi
    .get(`${businessPartnerId}/master-data`, {
      params: { useDraft: true },
      headers: { version: 2 },
    })
    .catch((err) => {
      return err.response && err.response.status === 404
        ? Promise.resolve(emptyMasterdata)
        : Promise.reject(err);
    });
}

/**
 * Fetch attributes for Merchant size charts
 * @param businessPartnerId
 */
export function fetchMerchantSizeCharts(
  businessPartnerId: string
): Promise<Array<SizeChart>> {
  return masterdataApi
    .get(`${businessPartnerId}/master-data/size-charts`)
    .then((data: any) => data.items);
}

export function changeMasterdata(
  businessPartnerId: string,
  data: Masterdata
): Promise<Masterdata> {
  return masterdataApi.put(`${businessPartnerId}/master-data`, data, {
    headers: { version: 2 },
  });
}

export function exportMasterdata(businessPartnerId: string) {
  return masterdataApi.post(`${businessPartnerId}/master-data/export`);
}

export function fetchExportLang(
  businessPartnerId: string
): Promise<string | null> {
  return masterdataApi
    .get(`${businessPartnerId}/export-language`)
    .catch((err) => {
      return err.response && err.response.status === 404
        ? Promise.resolve(emptyMDExportLanguage)
        : Promise.reject(err);
    })
    .then((data: MasterdataExportLang) => data.exportLanguage);
}

// TODO: add return type Promise<MasterdataExportLang>
export function changeExportLang(
  businessPartnerId: string,
  exportLanguage: string
) {
  const data: MasterdataExportLang = { exportLanguage };
  return masterdataApi.put(`${businessPartnerId}/export-language`, data);
}

interface LogsParams {
  action?: string;
  limit?: number;
}

function fetchLogs(
  businessPartnerId: string,
  params: LogsParams
): Promise<MasterdataLogItem[]> {
  return (
    masterdataApi
      .get(`${businessPartnerId}/master-data/logs`, { params })
      // @ts-ignore
      .then((data) => data.items)
  );
}

export function fetchExportLogs(businessPartnerId: string) {
  return fetchLogs(businessPartnerId, {
    action: MD_LOG_ACTION_EXPORT,
    limit: 20,
  });
}

/**
 * Check if there is a saved and not exported masterdata
 *
 * @param businessPartnerId Merchant id
 */
export function fetchIsSavedNotExported(
  businessPartnerId: string
): Promise<boolean> {
  return fetchLogs(businessPartnerId, {
    action: `${MD_LOG_ACTION_EXPORT},${MD_LOG_ACTION_SAVE}`,
    limit: 1,
  }).then((logs) => {
    // No logs means no masterdata saved
    if (!logs.length) {
      return false;
    }

    // Last action is Saved means Not Exported
    return logs[0].action === MD_LOG_ACTION_SAVE;
  });
}
