<template lang="pug">
  div.grey.lighten-5.py-5
    v-row()
      v-col(
        v-for="carrierInfo in list"
        :key="carrierInfo.id"
        cols="12"
        )
          DeliveryMethodCard(
            :details="carrierInfo"
            :business-model-rule="getBusinessModelRule(carrierInfo)"
            :on-delete="onDelete"
            :disable-delete="list.length === 1"
            :invalid-selection="!getDeliveryProduct(carrierInfo)"
            )
</template>
<script lang="ts">
import Vue from 'vue';
import DeliveryMethodCard from 'Merchant/contract/components/contract-carrier-new/delivery-method-card.vue';
import { MerchantTypeT } from 'react-packages/apiSpecification/merchants';

export default Vue.extend({
  name: 'DeliveryMethodCards',
  components: { DeliveryMethodCard },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    onDelete: {
      type: Function,
      required: true,
    },
    allowedDeliveryProducts: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ...this.mapState({
        merchantProfile: 'flow.general.merchant.data',
      }),
    };
  },
  // TODO: remove getBusinessModelRule and related after PT-23747
  methods: {
    getBusinessModelRule(selection: Object) {
      const delieryProduct = this.getDeliveryProduct(selection);
      return delieryProduct ? delieryProduct[this.getBusinessModelType()] : '';
    },
    getDeliveryProduct(selection: Object) {
      return this.allowedDeliveryProducts.find(
        // @ts-ignore
        (adp) => adp.deliveryProduct.id === selection.id
      );
    },
    getBusinessModelType() {
      return this.merchantProfile.merchantType ===
        MerchantTypeT.CONNECTED_RETAIL
        ? 'connectedRetailRule'
        : 'partnerProgramRule';
    },
  },
});
</script>
<style lang="scss" scoped></style>
