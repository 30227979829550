<template lang="pug">
    v-dialog(
    v-model="showCarrierDeleteWarningDialog"
    width="700"
    )
      v-flex(d-flex style="background-color: white")
        div.py-4.pl-4.mt-4
          v-icon.mr-5.mb-1(color="secondary" size="100") mdi-alert
        div.py-5.pr-5
          v-card-title.title.font-weight-bold
            | Delete configuration?
          v-card-text
            p.black--text
              | If you delete this configuration, <b>all related delivery methods will be deleted as
              | well.</b> You will need to set up a new carrier configuration that includes at least
              | one mandatory delivery method. Please note that the deletion will only occur 
              | once you have added at least one mandatory delivery product from the new carrier.
          v-card-actions
            v-spacer
            v-btn.mt-5.mb-6.ml-2.text-none.rounded.font-weight-bold(
              text
              color="black"
              @click="cancelCb"
              outlined
            ) Cancel
            v-btn.mt-5.mb-6.ml-2.text-none.white--text.rounded.font-weight-bold.delete-btn(
                @click="confirmCb"
                :loading="loading"
            ) Delete
  </template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  props: {
    confirmCb: {
      type: Function,
      required: true,
    },
    cancelCb: {
      type: Function,
      required: true,
    },
    showCarrierDeleteWarningDialog: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.delete-btn {
  border: 1px solid transparent;
  background-color: #b20e !important;
  box-shadow: none;
}
</style>
