import { AxiosResponse } from 'axios';
import * as Sentry from '@sentry/browser';
import {
  getEnv,
  getSentryDSN,
  isSentryActive,
} from 'react-packages/utils/configs';

export const reportApiErrorToSentry = (response: AxiosResponse): void => {
  if (response.config.url?.endsWith('master-data/export')) {
    return;
  }
  const isMonitoringActive = isSentryActive();

  if (isMonitoringActive) {
    Sentry.captureException(`API Error ${response.status}`, {
      level: Sentry.Severity.Error,
      tags: { uiUrl: window.location.href },
      extra: {
        httpStatus: response.status,
        errorResponse: JSON.stringify(response.data),
      },
    });
  }
};

export const reportErrorToSentry = (error: Error): void => {
  Sentry.captureException(error, {
    level: Sentry.Severity.Error,
    tags: { uiUrl: window.location.href },
  });
};

export const initReactSentry = (): void => {
  const sentryDSN = getSentryDSN();
  const environment = getEnv();

  if (isSentryActive() && sentryDSN && environment) {
    Sentry.init({
      dsn: sentryDSN,
      environment: environment,
    });
  }
};
