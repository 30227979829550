export type IntegrationTypeT = 'PARTNER_PROVIDER' | 'DIRECT';
export type MerchantFulfillmentTypeT = 'ZALANDO' | 'PARTNER' | 'ALL';
export type MerchantServiceLevelT = 'PLATINUM' | 'GOLD' | 'SILVER';
export type SalesforceIntegrationTypeT = 'FULL' | 'PARTIAL' | 'MISSING';
export type RejectionScopeT = 'PARTNER_FACING' | 'INTERNAL';

export enum MerchantTypeT {
  BRAND = 'BRAND',
  RETAILER = 'RETAILER',
  DISTRIBUTOR = 'DISTRIBUTOR',
  SERVICE_PROVIDER = 'SERVICE_PROVIDER',
  CONNECTED_RETAIL = 'CONNECTED_RETAIL',
}

export enum PreferredLanguageT {
  German = 'de-DE',
  English = 'en-GB',
}

export type FinancialReviewStatusT =
  | 'IN_PROGRESS'
  | 'IN_FINANCIAL_REVIEW'
  | 'FINANCIAL_APPROVED'
  | 'FINANCIAL_REJECTED'
  | 'DEACTIVATED';

export enum FactoringTypeT {
  One = '1.0',
  Two = '2.0',
}

export interface MerchantT {
  approverComment: string;
  rejectionScope?: RejectionScopeT;
  rejectedSections?: Array<string>;
  billingCurrency: string;
  businessPartnerId: string;
  companyName: string;
  createdAt: string;
  createdBy: string;
  financialStatusModifiedAt?: string;
  financialStatusModifiedBy: string;
  fulfillmentType: MerchantFulfillmentTypeT;
  integrationType: IntegrationTypeT;
  hasBeenOnboarded: boolean;
  legalEntityId: string;
  merchantType: MerchantTypeT;
  modifiedAt: string;
  modifiedBy: string;
  name: string;
  preferredLanguage: PreferredLanguageT;
  partnerProvider?: {
    api: string;
    id: number;
    name: string;
    platformIntegratorId?: string;
  };
  serviceLevel: MerchantServiceLevelT;
  status: FinancialReviewStatusT;
  onboardingResponsibility: 'ZDIRECT' | 'MERCHANT_PROFILE';
  taxId?: string;
  vatId?: string;
  salesforceIntegration: SalesforceIntegrationTypeT;
  factoring: FactoringTypeT;
}
