import { Component, ReactElement } from 'react';
import AppError from 'react-packages/pages/Error/AppError';
import { reportErrorToSentry } from 'react-packages/utils/sentry';

class ErrorBoundary<T extends { children: ReactElement }> extends Component<
  T,
  { error: boolean }
> {
  static getDerivedStateFromError() {
    return { error: true };
  }

  constructor(props: T) {
    super(props);

    this.state = { error: false };
  }

  componentDidCatch(error: Error): void {
    // eslint-disable-next-line no-console
    console.error(error);
    reportErrorToSentry(error);
  }

  render(): ReactElement {
    const { error } = this.state;

    if (error) return <AppError />;

    return this.props.children;
  }
}

export default ErrorBoundary;
