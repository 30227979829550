import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    VDialog,
    {
      attrs: { width: "700" },
      model: {
        value: _vm.showCarrierDeleteWarningDialog,
        callback: function ($$v) {
          _vm.showCarrierDeleteWarningDialog = $$v
        },
        expression: "showCarrierDeleteWarningDialog",
      },
    },
    [
      _c(
        VFlex,
        {
          staticStyle: { "background-color": "white" },
          attrs: { "d-flex": "" },
        },
        [
          _c(
            "div",
            { staticClass: "py-4 pl-4 mt-4" },
            [
              _c(
                VIcon,
                {
                  staticClass: "mr-5 mb-1",
                  attrs: { color: "secondary", size: "100" },
                },
                [_vm._v("mdi-alert")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "py-5 pr-5" },
            [
              _c(VCardTitle, { staticClass: "title font-weight-bold" }, [
                _vm._v("Delete configuration?"),
              ]),
              _c(VCardText, [
                _c("p", { staticClass: "black--text" }, [
                  _vm._v("If you delete this configuration, "),
                  _c("b", [
                    _vm._v(
                      "all related delivery methods will be deleted as\nwell."
                    ),
                  ]),
                  _vm._v(
                    " You will need to set up a new carrier configuration that includes at least\none mandatory delivery method. Please note that the deletion will only occur \nonce you have added at least one mandatory delivery product from the new carrier."
                  ),
                ]),
              ]),
              _c(
                VCardActions,
                [
                  _c(VSpacer),
                  _c(
                    VBtn,
                    {
                      staticClass:
                        "mt-5 mb-6 ml-2 text-none rounded font-weight-bold",
                      attrs: { text: "", color: "black", outlined: "" },
                      on: { click: _vm.cancelCb },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    VBtn,
                    {
                      staticClass:
                        "mt-5 mb-6 ml-2 text-none white--text rounded font-weight-bold delete-btn",
                      attrs: { loading: _vm.loading },
                      on: { click: _vm.confirmCb },
                    },
                    [_vm._v("Delete")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }