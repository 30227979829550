import * as Raven from 'raven-js';
import * as angularPlugin from 'raven-js/dist/plugins/angular';
import * as vuePlugin from 'raven-js/dist/plugins/vue';
import * as configService from 'Common/services/config.service';
import Vue from 'vue';
import { initReactSentry } from 'react-packages/utils/sentry';

export default {
  initialize() {
    const isSentryActive = configService.isSentryActive();
    const environment = configService.getEnv();
    const deps = [];

    if (isSentryActive) {
      Raven.config(configService.getSentryDSN(), {
        environment,
      })
        .addPlugin(angularPlugin, angular)
        .addPlugin(vuePlugin, Vue)
        .install();

      deps.push('ngRaven');

      initReactSentry();
    }

    return angular.module('mcp.admin.monitoring', deps);
  },
};
