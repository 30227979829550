import { Box, Skeleton, Typography } from '@partner-tech/pax-ui-kit';
import { ReactElement } from 'react';

interface LoadingBlockPropsT {
  title?: string;
}

const SectionLoader = ({ title }: LoadingBlockPropsT): ReactElement => {
  return (
    <Box>
      {title && <Typography>{title}</Typography>}
      <Skeleton contentType="rectangular" width="100%" height={64} />
    </Box>
  );
};

export default SectionLoader;
