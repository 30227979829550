<template lang="pug">
  v-card(
    outlined
    tile
    rounded="sm"
    :class="{'invalid-selection': invalidSelection}"
  ).pa-6.rounded
    v-flex.d-flex.justify-space-between.align-center
      div
        v-chip(color="#d6deec", text-color="#283b8a", small)
          span.font-weight-bold {{carrierInfo.deliveryDestinationType}}
        v-chip(v-if="businessModelRule")(color="#d6deec", text-color="#283b8a", small).ml-2
          span.font-weight-bold {{businessModelRule}}
        div.my-4
          span.text-subtitle-2.font-weight-bold.mr-2.black--text Opt-in services:
          span.text-body-2
            | {{carrierInfo.configuredOptionalDeliveryCapabilities.join(', ') || 'Not Specified'}}

        div
          span.text-subtitle-2.font-weight-bold.mr-2.black--text
            | {{carrierInfo.capabilities.length > 1 ? 'Parcel Services' : 'Parcel Service'}}:
          span.text-body-2 {{carrierInfo.capabilities.join(', ') || 'Not Specified'}}
      div
        v-btn.mt-4.text-none.rounded.font-weight-bold(
          :loading="isLoading"
          outlined
          color="black"
          @click="handleDelete(carrierInfo.id)"
          :disabled="disableDelete"
        ) Delete
    div(v-if="invalidSelection").mt-4.alert-text
      v-icon.mr-1.justify-center mdi-information-outline
      span.mt-1 Invalid delivery method. Please delete it and set up a new one.

</template>
<script lang="ts">
import Vue from 'vue';
import {
  DELIVERY_DESTINATIONS,
  OPTIONAL_CAPABILITIES,
  PARCEL_SERVICES,
} from 'Common/constants/carriers.constant';
import { ConfiguredCarrierResponseItemT } from 'Api/endpoints/sales-channel/carrier.endpoint';

export default Vue.extend({
  name: 'DeliveryMethodCard',
  props: {
    details: {
      type: Object as () => ConfiguredCarrierResponseItemT,
      required: true,
    },
    onDelete: {
      type: Function,
      required: true,
    },
    disableDelete: {
      type: Boolean,
      required: true,
    },
    businessModelRule: {
      type: String,
      required: true,
    },
    invalidSelection: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      carrierInfo: this.details,
      isLoading: false,
    };
  },
  mounted() {
    this.carrierInfo = {
      ...this.details,
      deliveryDestinationType:
        DELIVERY_DESTINATIONS[this.details.deliveryDestinationType],
      capabilities: this.details.capabilities.map((cp) => PARCEL_SERVICES[cp]),
      configuredOptionalDeliveryCapabilities:
        this.details.configuredOptionalDeliveryCapabilities.map(
          (cp) => OPTIONAL_CAPABILITIES[cp]
        ),
    };
  },
  methods: {
    handleDelete(id: string) {
      this.isLoading = true;
      this.onDelete(id).then(() => (this.loading = false));
    },
  },
});
</script>
<style lang="scss" scoped>
.invalid-selection {
  border: 1px solid #b20e05;
  background-color: #f8edea;
}
.alert-text {
  font-weight: bold;
  color: #7e1f12;
}
.alert-text i {
  color: #7e1f12 !important;
}
</style>
