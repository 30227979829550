import {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useState,
} from 'react';
import { Snackbar, SnackbarProps } from '@partner-tech/pax-ui-kit';

interface SnackbarContextValueT {
  showSnackbar: (snackbarConfig: SnackbarProps) => void;
}

export const SnackbarContext = createContext<SnackbarContextValueT>({
  showSnackbar: () => {},
});

interface SnackbarContextProviderPropsT {
  children: ReactNode;
}

export const SnackbarContextProvider = ({
  children,
}: SnackbarContextProviderPropsT): ReactElement => {
  const [snackbarList, setSnackbarList] = useState<SnackbarProps[]>([]);

  const addToList = (snackbarConfig: SnackbarProps) => {
    setSnackbarList((currentList) => {
      return [...currentList, snackbarConfig];
    });
  };

  const removeFromList = (index: number) => () => {
    setSnackbarList((currentList) => {
      return currentList.filter((_, i) => i !== index);
    });
  };

  const renderSnackbar = (snackbarConfig: SnackbarProps, index: number) => {
    return <Snackbar {...snackbarConfig} onClose={removeFromList(index)} />;
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar: addToList }}>
      {children}
      {snackbarList.map(renderSnackbar)}
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = (): SnackbarContextValueT =>
  useContext(SnackbarContext);
