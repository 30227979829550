import { IComponentOptions } from 'angular';
import { ComponentType, ReactElement } from 'react';
import { PaxThemeProvider } from '@partner-tech/pax-ui-kit/styles';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import ErrorBoundary from 'react-packages/wrapper/ErrorBoundary';
import { SnackbarContextProvider } from 'react-packages/context/SnackbarContext';
import { RouterDomContextProvider } from 'react-packages/context/RouterDomContext';
import reactQueryClient from './queryClient';
import { reactToAngular } from './reactToAngular';

type InjectedParamsT = {
  $stateParams: Record<string, string>;
};

type ComponentPropsT = {
  params: Record<string, string>;
};

interface WrapReactPropsT {
  component: ComponentType<ComponentPropsT>;
}

const angularInjectNames = ['$stateParams'];

function getComponentProps({
  $stateParams,
  ...restProps
}: InjectedParamsT): ComponentPropsT {
  return {
    ...restProps,
    params: $stateParams,
  };
}

const css = `
  body {
    font-weight: 300;
    font-family: 'Ubuntu', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    color: #4a4a4a;
  }
`;

function wrapProviders(
  Comp: ComponentType<ComponentPropsT>
): ComponentType<InjectedParamsT> {
  const ReactRootComponent = (props: InjectedParamsT): ReactElement => {
    return (
      <PaxThemeProvider>
        <style>{css}</style>
        <RouterDomContextProvider $stateParams={props.$stateParams}>
          <QueryClientProvider client={reactQueryClient}>
            <ErrorBoundary>
              <SnackbarContextProvider>
                <Comp {...getComponentProps(props)} />
              </SnackbarContextProvider>
            </ErrorBoundary>
            <ReactQueryDevtools buttonPosition="bottom-right" />
          </QueryClientProvider>
        </RouterDomContextProvider>
      </PaxThemeProvider>
    );
  };

  return ReactRootComponent;
}

function wrapReact(
  component: ComponentType<ComponentPropsT>
): IComponentOptions {
  return reactToAngular(
    wrapProviders(component),
    undefined,
    angularInjectNames
  );
}

export default wrapReact;
