import { Box, Grid, Typography } from '@partner-tech/pax-ui-kit';
import { ReactElement } from 'react';

export interface ItemRowPropsT {
  label: string;
  value?: string | ReactElement;
}

const ItemRow = ({ label, value = '' }: ItemRowPropsT): ReactElement => {
  return (
    <Box mb={2}>
      <Grid container justifyContent="flex-left" alignItems="center">
        <Grid xs={3}>
          <Box textTransform="uppercase">
            <Typography variant="caption1">{label}</Typography>
          </Box>
        </Grid>
        <Grid xs={9}>
          <Typography variant="body2">{value}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ItemRow;
