import Cookies from 'js-cookie';
import { reportApiErrorToSentry } from 'react-packages/utils/sentry';
import { ErrorResponseT } from 'react-packages/utils/apiFetch/types';

function handleUnauthorized(): void {
  Cookies.set('returnUrl', window.location.href);
  window.location.assign('/auth/zalando/login');
}

const handleApiError = (
  errorResponse: ErrorResponseT
): Promise<ErrorResponseT> => {
  const { response } = errorResponse;

  if (response.status !== 401 && response.status !== 404) {
    reportApiErrorToSentry(response);
  }

  if (response.status === 401) {
    handleUnauthorized();
  }

  return Promise.reject(errorResponse);
};

export default handleApiError;
